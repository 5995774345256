.btn-bg {
  background: #2a3042 !important;
  color: white !important;
  border: 1px solid #2a3042;
}
.eye_css {
  cursor: pointer;
}
.eye_css:hover {
  color: red;
}

body {
  font-family: "Poppins", sans-serif;
}
